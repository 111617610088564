@import "normalize.css";

@font-face {
  font-family: "OPS Cubik";
  src: url("./ops-cubik/OPSCubik-Regular.eot");
  src: url("./ops-cubik/OPSCubik-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./ops-cubik/OPSCubik-Regular.woff2") format("woff2"),
    url("./ops-cubik/OPSCubik-Regular.woff") format("woff");
  font-weight: 400;
}

:root {
  --background-color: #d8d8d8;
  --contrast-color: rgb(0, 0, 0);
}

* {
  box-sizing: border-box;
}

html {
  background: var(--contrast-color);
  font-size: 85%;
}

body {
  background: var(--background-color);
  font-family: "OPS Cubik", mono;
  line-height: 1.3rem;
  color: var(--contrast-color);
  animation: fadein ease-in 1s;
}

a:link,
a:visited {
  color: var(--contrast-color);
}

a:hover {
  text-decoration: none;
}

header a:link,
a:visited {
  text-decoration: none;
}

.button-link:hover {
  filter: invert(1);
}

main hr {
  border-top: 1px solid var(--contrast-color);
  border-bottom: 0 solid var(--contrast-color);
}

main {
  position: relative;
  padding: 0 1.5rem 4rem 1.5rem;
  transition: opacity 500ms ease-in 1s;
  animation: fadein ease-in 1s;
  min-height: 100vh;
}

footer {
  background: var(--contrast-color);
  text-align: center;
  padding: 2rem 2rem 4rem 2rem;
  color: #fff;
}

footer img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 32em;
  vertical-align: bottom;
  margin: 2rem 0;
  filter: invert(0);
}

footer a:link,
footer a:visited {
  filter: invert(1);
}

footer hr {
  margin: calc(1em * 2) 0;
  border-top: 1px solid #ffffff;
  border-bottom: 0 solid #ffffff;
}

p {
  margin-bottom: 1em;
}
img {
  vertical-align: text-bottom;
}

.inline-block {
  display: inline-block;
}
.display-none-xs {
  display: none;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.flex {
  display: flex;
}
.justify-content {
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-center {
  justify-content: center;
}

.mt1 {
  margin-top: 1rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.m40 {
  margin: 4rem 0;
}
.m4060 {
  margin: 4rem 0 6rem 0;
}

.xs-mb {
  margin-bottom: 1rem;
}

.p-75 {
  padding: 0.75rem;
}
.p1 {
  padding: 1rem;
}
.p20 {
  padding: 2rem 0;
}
.p2 {
  padding: 2rem;
}
.p2242 {
  padding: 2rem 2rem 4rem 2rem;
}

.text-align-center {
  text-align: center;
}

.border-1px {
  border: 1px solid var(--contrast-color);
}
.radius {
  border-radius: 26px;
}

.w14 {
  width: 14rem;
}
.w50 {
  width: 100%;
}
.w75 {
  width: 100%;
}
.w100 {
  width: 100%;
}
.mw100 {
  max-width: 100%;
}

.h-auto {
  height: auto;
}
.h4 {
  height: 4rem;
}
.h7 {
  height: 7rem;
}
.h5 {
  height: 5rem;
}
.h100 {
  height: 100%;
}

.icon {
  width: 1.75rem;
  height: 1.75rem;
  margin: 0.75rem;
}

.left {
  left: 0;
}
.top {
  top: 0;
}
.bottom {
  bottom: 0;
}
.right {
  right: 0;
}
.top-100 {
  top: -100%;
}

.center-y {
  top: 50%;
  transform: translateY(-50%);
}
.center-x {
  left: 50%;
  transform: translateX(-50%);
}

.max-width {
  max-width: 1200px;
}

.inverted {
  filter: invert(1);
}
.zoom-in {
  cursor: zoom-in;
}
.zoom-out {
  cursor: zoom-out;
}

.z1 {
  z-index: 1;
}
.z2 {
  z-index: 2;
}

.bWhite {
  background: rgb(255, 255, 255);
}

.o1 {
  opacity: 1;
}
.o0 {
  opacity: 0;
}

.item:hover img {
  filter: invert(1);
}

.transition {
  transition: opacity 500ms;
}

.text-bottom {
  vertical-align: bottom;
}

/* Item pages */

.button {
  padding: calc(1em * 1.2);
  cursor: pointer;
}

.button:hover {
  filter: invert(1);
}

.aspect-ratio {
  position: relative;
  padding-bottom: 75%;
  transition: opacity 1s;
}

.loading-video {
  background: black;
  color: white;
}

.project-headline {
  padding: 0em 0 2rem 0;
}

.controls {
  animation: fadeout ease-in 3s;
}

.controls:hover {
  opacity: 1;
}

@media only screen and (min-width: 668px) {
  main {
    padding: 0 2rem 4rem 2rem;
  }
  .fixed {
    position: fixed;
  }
  .m01 {
    margin: 0 1rem;
  }
  .xs-mb {
    margin-bottom: 2rem;
  }
  .justify-content {
    justify-content: space-between;
  }
  .w50 {
    width: calc(50% - 2rem);
  }
  .w75 {
    width: 75%;
  }
  .display-none-xs {
    display: block;
  }
  .project-headline {
    padding: 2.75em 0 2.25em 0;
  }
  .loading,
  .error {
    padding-top: 3.75rem;
  }

  .item {
    height: 7em;
  }
  .item .list-logotype {
    height: 12em;
  }
  .logotype-container {
    height: 27rem;
  }

  .logotype-container img {
    width: 64em;
    height: auto;
    max-width: 100%;
  }

  .icon {
    width: 2rem;
    height: 2rem;
    margin: 1rem;
  }
}

.modal {
  transition: top 200ms;
}

.modal-main {
  max-height: calc(100vh - 8rem);
  margin: 2rem;
  text-align: center;
}

.modal-image {
  margin-bottom: 2rem;
  max-width: 100%;
  max-height: calc(100vh - 16rem);
}

.modal-close-button {
  height: 4rem;
  position: absolute;
  bottom: 0;
  margin: 2rem 0;
  left: 50%;
  transform: translateX(-50%);
}

.modal-close-button:hover {
  opacity: 0.75;
  cursor: pointer;
}

.gradient {
  background: rgb(216, 216, 216);
  background: -moz-linear-gradient(
    180deg,
    rgba(216, 216, 216, 1) 60%,
    rgba(216, 216, 216, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(216, 216, 216, 1) 60%,
    rgba(216, 216, 216, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(216, 216, 216, 1) 60%,
    rgba(216, 216, 216, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d8d8d8",endColorstr="#d8d8d8",GradientType=1);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
